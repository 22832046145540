import { render, staticRenderFns } from "./pitkaan-odotettu-reissu-edessa-ja-rahat-vahissa.vue?vue&type=template&id=8dbf2c06&"
import script from "./pitkaan-odotettu-reissu-edessa-ja-rahat-vahissa.vue?vue&type=script&lang=js&"
export * from "./pitkaan-odotettu-reissu-edessa-ja-rahat-vahissa.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports