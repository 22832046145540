<template>
  <div class="content">
    <breadcrumb
      :path="{'Talous': '/talous', 'Korkokatto päättyy joulukuussa': ''}"
    />
    <img
      style="width: 100%;"
      :src="require(
        `~/assets/img/subpage/${this.$route.path.substring(1)}.jpg`
      )"
      :alt="this.$route.path.substring(1)"
    >
    <h1>
      Korkokatto päättyy joulukuussa – toimi näin,
      jos sinulla on kulutusluottoja
    </h1>
    <p style="font-weight: 900;">
      Heinäkuussa säädetty 10 prosentin korkokatto päättyy vuoden vaihteessa.
      Väliaikaisen korkokaton lisäksi kulutusluottojen yleinen korkotaso on
      laskenut selvästi tämän vuoden aikana. Nyt on korkea aika kilpailuttaa
      vanhat lainat, jos haluaa hyötyä korkoalesta. Kulutusluottoa ottaessasi
      kannattaa huomioida se, että lainan korko saattaa nousta korkokaton
      päätyttyä 1.1.2021 alkaen, jos lainasopimuksessa on sovittu yli 10
      prosentin nimelliskorosta.
    </p>
    <p>
      Heinäkuun alusta voimaan tullut vakuudettomien lainojen korkokatto on
      vaikuttanut selvästi kulutusluottojen korkoihin. Vaikka laki koskee vain
      loppuvuotta, olemme Rahalaitoksella havainneet myös pidemmälle ajalle
      tarjottujen lainojen korkotason laskeneen selvästi alkuvuodesta. Nyt
      kannattaa siis kilpailuttaa tai yhdistää vanhat lainat ja luotot ennen
      vuoden vaihdetta, jolloin on odotettavissa korkojen nousua
      nykyiseltä tasolta.
    </p>
    <h2>
      Yhdistelylainojen korkotaso edullisimmillaan nyt
    </h2>
    <p>
      Rahalaitoksen käyttäjätilastoihin perustuvan selvityksen mukaan eniten
      kasvava lainamuoto kulutusluotoissa on yhdistelylainat.
      <nuxt-link to="/yhdistelylaina">Yhdistelylainojen</nuxt-link> korkotaso
      on ollut laskusuunnassa syyskuusta 2019 alkaen. Silloin korkokatto laski
      20 prosenttiin ja myös muita lainoista perittäviä kustannuksia
      rajoitettiin. Tänä vuonna korkotaso on laskenut edelleen sekä
      lainsäädännön että kilpailutilanteen tiukennuttua. Me Rahalaitoksella
      arvioimme kuitenkin, että vuoden vaihteessa korot kääntyvät
      todennäköisesti nousuun, kun korkosäätely lievenee.
    </p>
    <p>
      Tällä hetkellä yhdistelylainaa voi saada alhaisimmillaan ilman vakuuksia
      jopa alle 5 prosentin korolla. Kaikki vanhat lainat kannattaa
      kilpailuttaa uudestaan nopeasti ennen kuin korkotaso kääntyy nousuun.
    </p>
    <h2>
      Laina voi tulla halvemmaksi kuin osamaksu tai luottokortti
    </h2>
    <p>
      Jos suunnittelet suurempia ostoksia, joihin sinulla ei ole vielä kokonaan
      rahoitusta kannattaa miettiä tarkkaan, miten rahoitat hankinnan.
      Heinäkuussa voimaan astunut lakimuutos ei koske luottokortteja tai
      osamaksuja, joiden korko voi olla yhä jopa 20 %.
      <nuxt-link to="/">Kilpailutuspalvelun</nuxt-link>
      kautta otettu vakuudeton laina voi tulla halvemmaksi
      kuin osamaksu tai luottokortin käyttö.
    </p>
    <h2>
      Kilpailuttamalla varmistat, ettet maksa liikaa korkoja
    </h2>
    <p>
      Rahalaitos kilpailuttaa lainasi turvallisesti 25 pankilla ja
      lainantarjoajalla, joiden kanssa se tekee yhteistyötä. Sinun ei
      tarvitse tehdä muuta kuin täyttää yksi hakemus
      <nuxt-link to="/">Rahalaitoksen</nuxt-link> sivuilla.
      Hakemuksen lähettäminen ei vielä sido mihinkään, vaan voit rauhassa
      katsoa, millaisia tarjouksia saat, ja päättää sitten,
      hyväksytkö niistä jonkun.
    </p>
    <p>
      Varmista, että saat parhaan mahdollisimman lainan – täytä
      hakemus Rahalaitoksen sivuilla ja katso, millaisen koron voit saada.
    </p>
    <span class="miniform-header">Lainan kilpailutus 60 000 € asti</span>
    <mini-form />
    <share
      text="Korkokatto päättyy joulukuussa
        – toimi näin, jos sinulla on kulutusluottoja"
    />
  </div>
</template>

<script>
export default {
  layout: 'articles',
  name: 'KorkokattoPaattyyJoulukuussa',
  components: {
    miniForm: () => import('~/components/subpage/miniform'),
    share: () => import('~/components/subpage/share'),
    breadcrumb: () => import( '~/components/breadcrumb' )
  },
  head () {
    return {
      title: `Korkokatto päättyy joulukuussa
        – toimi näin, jos sinulla on kulutusluottoja`,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: `Heinäkuussa säädetty 10 prosentin korkokatto
            päättyy vuoden vaihteessa. Väliaikaisen korkokaton lisäksi
            kulutusluottojen yleinen korkotaso on laskenut
            selvästi tämän vuoden aikana.`
        }
      ]
    }
  }
}
</script>
