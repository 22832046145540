<template>
  <div class="loan-slider" :class="{ 'loan-slider__style-2': sliderStyle === 2 }">
    <div class="has-text-weight-bold is-uppercase loan-slider__title">
      <span>{{ title }}</span>
      <input
        v-if="sliderStyle === 1"
        ref="input"
        :key="name"
        v-model="sliderValue"
        size="9"
        class="input is-transparent"
        type="text"
        :name="name"
        :aria-label="name"
        @change="updateSlider"
      >
    </div>
    <div class="field has-addons has-addons-centered loan-slider__container">
      <span class="loan-slider__button">
        <span
          class="icon"
          @click="updateSlider( $event, -( $refs.slider.noUiSlider.steps()[0][0] ) )"
        >
          <font-awesome-icon :icon="['far', 'minus-circle']" size="lg" fixed-width />
        </span>
      </span>
      <div class="control loan-slider__slider">
        <div ref="slider" />
      </div>
      <span class="control loan-slider__button">
        <span
          class="icon"
          @click="updateSlider( $event, $refs.slider.noUiSlider.steps()[0][1] )"
        >
          <font-awesome-icon :icon="['far', 'plus-circle']" size="lg" fixed-width />
        </span>
      </span>
    </div>
    <input
      v-if="sliderStyle === 2"
      ref="input"
      :key="name"
      v-model="sliderValue"
      style="width: 100%;"
      class="input is-transparent"
      type="text"
      :name="name"
      @change="updateSlider"
    >
  </div>
</template>

<script>
import noUiSlider from 'nouislider'

export default {
  $_veeValidate: {
    value () {
      return this.value
    },
    name () {
      return this.name
    }
  },
  props: {
    intervals: {
      default: () => {
        return {}
      },
      type: Object
    },
    max: {
      default: 60000,
      type: Number
    },
    min: {
      default: 500,
      type: Number
    },
    name: {
      required: true,
      type: String
    },
    sliderStyle: {
      default: 1,
      type: Number
    },
    start: {
      default: null,
      type: Number
    },
    step: {
      default: 100,
      type: Number
    },
    title: {
      type: String,
      default: 'Lainan suuruus'
    },
    format: {
      type: Function,
      default: ( value ) => {
        let amount = Math.round( value / 100 ) * 100
        if ( amount > 999 ) {
          amount = amount.toString().replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ' )
        }
        return amount + ' €'
      }
    },
    value: {
      default: null,
      type: Number
    }
  },
  data () {
    let range = {
      min: this.min,
      max: this.max
    }
    if ( this.intervals ) {
      // Min must be in beggining and max at the end of object.
      range = Object.assign(
        { min: this.min },
        this.intervals,
        { max: this.max }
      )
    }
    return {
      sliderValue: this.value,
      slider: {
        keyboardSupport: false, // @todo Finish issue #28
        start: this.value || this.start,
        step: this.step,
        connect: [true, false],
        range,
        format: {
          to: this.format,
          from ( value ) {
            return Number( value.replace( /\D/g, '' ) )
          }
        }
      }
    }
  },
  mounted () {
    // Create slider
    noUiSlider.create( this.$refs.slider, this.slider )
    // Attach update event to slider
    this.$refs.slider.noUiSlider.on( 'update', ( values, handle ) => {
      this.sliderValue = values[handle]
      this.$nextTick( () => {
        const value = isNaN( this.sliderValue )
          ? this.sliderValue.replace( /\D/g, '' )
          : this.sliderValue
        this.$emit( 'input', parseInt( value ) )
      } )
    } )
    // Watch value event for changes
    const watchMethod = function ( value ) {
      this.$refs.slider.noUiSlider.set( value )
    }
    // Unwatch slider while moved by mouse
    let unwatch = this.$watch( 'value', watchMethod )
    this.$refs.slider.noUiSlider.on( 'start', () => {
      unwatch()
    } )
    this.$refs.slider.noUiSlider.on( 'end', () => {
      unwatch()
      unwatch = this.$watch( 'value', watchMethod )
      this.$emit( 'sliderEnd' )
    } )
  },
  beforeDestroy () {
    this.$refs.slider.noUiSlider.off()
  },
  methods: {
    updateSlider ( _event, amount = 0 ) {
      if ( amount !== null ) {
        const value = isNaN( this.sliderValue )
          ? this.sliderValue.replace( /\D/g, '' )
          : this.sliderValue
        this.$refs.slider.noUiSlider.set( parseInt( value, 10 ) + amount )
        this.$nextTick( () => {
          this.$emit( 'sliderEnd' )
        } )
      }
    },
    updateMaxValue () {
      this.$nextTick( () => {
        this.$refs.slider.noUiSlider.updateOptions( {
          range: {
            min: this.min,
            max: this.max
          }
        } )
      } )
    }
  }
}
</script>
